import React from 'react';
import { Outlet, Link } from "react-router-dom";
import myImage from '../banner-image.jpg';

export default function About(){
	return(
		<div id="about" className="section background-whit">

			<div className="main-banner background-blac p-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
							<div className="caption header-text">
								<h5>Shoppers Customer Service</h5>
								<h2>E x p e r i e n c e s</h2>
								<p>
									During my time at Shoppers Drug Mart downtown,
									I juggled between being a merchandiser and a cashier,
									depending on what the store needed most at any given moment.
									<br/><br/>
									No matter the task, our team was always focused on making sure we gave our customers
									the best service possible.
									<br/><br/>
									We aimed to make every interaction smooth and tailored to each person's needs.
								</p>
							</div>
						</div>
						<div className="col-lg-4 offset-lg-2">
							<div className="right-image">
								<img src={myImage} alt="my pic" width={350}/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="main-banner p-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
							<div className="sprite-container">
								<div className="sprite"></div>
							</div>
						</div>
						<div className="col-lg-4 offset-lg-2">
							<div className="caption header-text">
								<h5 className="text-col">Things I learned while working at Shoppers</h5>
								<h2 className="text-col">S k i l l s</h2>
								<ul>
									<li>Communicating clearly to help a customer find the item they are looking for.
									</li>
									<li>Working with a team</li>
									<li>Conflict, Stress & Time Management</li>
									<li>Openness to criticism</li>
									<li>Resourcefulness</li>
								</ul>
								<a className="btn btn-primary"
								   href="https://www.freepik.com/search?format=search&last_filter=query&last_value=sprites&query=sprites"
								   alt="girl with red hair"
								   target="_blank" rel="noreferrer noopener">
									Sprite image @Freepik
								</a>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="main-banner background-blu p-5 text-blue">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
							<div className="caption header-text">
								<h5 className="tex-head-dark">BodyShop Customer Service</h5>
								<h2 className="tex-head-dark">E x p e r i e n c e s</h2>
								<p>
									I'm currently working part-time at the BodyShop as a Receiver/Stocker.
									<br/><br/>
									Our teams goal is to ensure that every interaction is seamless and personalized
									according to
									the individual's preference.
									<br/><br/>
									My duties are:
									<ul>
										<li>Receive shipments & check if there's an excess or missing items</li>
										<li>Update inventory after said check above^</li>
										<li>Stock items FIFO(First In First Out)</li>
										<li>Do inventory cycle counts</li>
										<li>Maintain order and cleanliness of the back room</li>
									</ul>


									<br/>
									<a className="btn btn-primary"
									   href="https://www.freepik.com/search?format=search&last_filter=query&last_value=sprites&query=sprites"
									   alt="bird"
									   target="_blank" rel="noreferrer noopener">
										Sprite image @Freepik
									</a>
								</p>
							</div>
						</div>
						<div className="col-lg-4 offset-lg-2">
							<div className="bird-container">
								<div className="bird"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main-banner p-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-1 align-self-center">

						</div>
						<div className="col-lg-9 offset-lg-2">
							<div className="caption header-text">
								<h5 className="text-col">A friendly message.</h5>
								<h2 className="text-col">N o t e :</h2>
								<p className="flex-smiley"><u>If ask, I can tell you more about my other past work experiences that are not included here.</u>
									<span className="font-smiley">&#128513;</span></p>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="main-banner background-grey p-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 align-self-center">
							<h2>PERCENTAGE</h2>
							<br/>
							<h4>Interpersonal</h4>
							<span>Customer Support <small>95%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "95%"}}>
								</div>
							</div>
							<span>Team Player<small>81%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="80"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "81%"}}>
								</div>
							</div>
							<span> Open to Feedback<small>87%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "87%"}}>
								</div>
							</div>
							<br/>
							<h4>Technical</h4>
							<span>Fron-End<small>73%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "73%"}}>
								</div>
							</div>
							<span>Back-End<small>90%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "90%"}}>
								</div>
							</div>
							<span>Adobe Photoshop<small>70%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "70%"}}>
								</div>
							</div>
							<span>Github ( Version Control )<small>77%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "77%"}}>
								</div>
							</div>
							<span>Filezilla ( FTP )<small>83%</small></span>
							<div className="progress">
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow="60"
									aria-valuemin="0"
									aria-valuemax="100"
									style={{width: "83%"}}>
								</div>
							</div>

						</div>
						<div className="col-lg-7 offset-lg-2">
							<div className="caption header-text">
								<h5>Junior Developer</h5>
								<h2>E x p e r i e n c e s</h2>
								<h4>Explanations and other info of *some webapps below are on &nbsp;
									<Link className="btn btn-warning" to="/portfolio">Portfolio</Link>
								</h4>
								<ul>
									<li>Created a YT clone using ReactJS, MaterialUI & RapidAPI.</li>
									<li>Created a PHP/AJAX Live Search.</li>
									<li>Created a Simple PHP Login with Sign-Up & Dashboard.</li>
									<li>Modified 2 template portfolio's to achieved certain objectives & personalized it
									</li>
									<li>Created a Random Quotes using ReactJS + API.</li>
									<li>Created a Jeopardy Random Edition using ReactJS + API.</li>
									<li>Created a Weather App using ReactJS + API.</li>
									<li>Created a MongoDB Movie Search App were you search the movie by description.
									</li>
									<li>Created a static Travler App for <b>mobile, tablet, laptop view</b> with HTML,
										CSS,
										JavaScript to render the photos & info from a pdf file provided by our
										instructor to
										a website.
									</li>
									<li>Created a static Photo Stocks website with HTML, CSS, JavaScript to render the
										photos & info from a pdf file provided by our instructor to a website.
									</li>
									<li>Created a one page unsolicited redesign of Yanalla Farms that is made with
										Joomla
										using WordPress as instructed by our instructor
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	)
}