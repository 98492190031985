import { Outlet, Link } from "react-router-dom";

const Nav = () => {
  return (
      <>
          <nav className="navbar navbar-expand-sm navbar-light bg-light">
              <Link className="nav-brand" to="/">K R A M ' S</Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                      <li className="nav-item">
                          <Link className="nav-link" to="/">Home</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/about">About</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/portfolio">Portfolio</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/development">Dev-Steps</Link>
                      </li>
                      {/* <li>
           <Link to="/whatido">Skills</Link>
         </li> */}
                      <li className="nav-item">
                          <Link className="nav-link" to="/contact">Contact</Link>
                      </li>
                  </ul>
              </div>
          </nav>
          <Outlet/>
      </>
  )
};

export default Nav;