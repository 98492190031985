import React from 'react'
import { Modal, Box, Typography } from '@mui/material';

const Modalport = (props) => {
  return (
    <Modal 
									open={props.open}
									onClose={props.handleClose}			
									>
									<Box sx={{ 
										bgcolor:'whitesmoke', 
										color:'black',
										position:'absolute',
										top:'50%',
										left:'50%',
										transform: 'translate(-50%,-50%)',
										width:{sx: '320', md: '400' },
										height: '90vh',
										overflowY:'auto',
										border:'0.75rem solid #1a1a1a',
										boxShadow:'24',
										p:4,
										borderRadius:'1rem'
										}}>
										<Typography textAlign='center' variant='h4' mb={2} fontWeight='bold' fontFamily='Pangolin'  sx={{ color: '#000' }}>
											{props.title} 
										</Typography>
										<Typography mb={1}>
											<div className='infor'>
												<div>
													<h5>Role</h5>
													<p>Developer</p>
												</div>
												<div>
													<h5>Tools</h5>
													<p>{props.tools.a}</p>
													<p>{props.tools.b}</p>
                                                    {props.tools.c && 
                                                        <p>{props.tools.c}</p> 
                                                    }
													
												</div>
											</div>
										</Typography>
										<Typography textAlign='center'>
											<p className='paragr' id="paraid">{props.desc}</p>
											<a className='paragr' id='myweblnk' href={props.mylink} alt="search" target="_blank" rel="noreferrer noopener">View Website</a>
											<img id="mimg" src={props.img} className="img-responsive" alt="portfolio img"/>	
										</Typography>
									</Box>	
	</Modal>
  )
}

export default Modalport