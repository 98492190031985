import React from 'react';

export default function Header(){
	return(
		<div>
			<div id="home" className="section">
				<div className="container">
					<div className="row">
						<div className="col-md-2"></div>
						<div className="col-md-8 wow fadeIn" >
							<h3>Hey there, I'm</h3>
							<h1>Mark Alejandro Kevin Ablao</h1>
							<h2 className="rotate">Junior Developer</h2>
							<p className="intro">
								Throughout my life, I have consistently derived a profound sense of achievement from resolving challenges through systematic exploration and experimentation. <br/><br/>This
								intrinsic motivation has been a cornerstone of my personal growth and has significantly influenced my approach to problem-solving. <br/><br/> <span
								className="intro-w">I love coding!</span>
							</p>
							<a href="#work" className="btn btn-default smoothScroll wow fadeInUp" >My Skills</a>
						</div>
						<div className="col-md-2"></div>
					</div>
				</div>
			</div>
			<div id="work" className="section">
			<div className="container">
				<div className="row">
					<div className="col-md-12 wow bounce">
						<h2>Skills</h2>
					</div>
				</div>  
				
				<div className="row">
					<div className="col-md-4 col-xs-11 wow fadeInUp" >
						<div className="media">
							<div className="media-object media-left">
								<i className="fa fa-paper-plane"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">Tools</h3>
								<p>Adobe Photoshop</p>
								<p>FileZilla</p>
								<p>Node Package Manager</p>
								<p>Composer</p>
								<p>Github Desktop/CMD</p>
								<p>Windows, Ubuntu</p>
							</div>
						</div>
					</div>

					<div className="col-md-4 col-xs-11 wow fadeInUp" >
						<div className="media">
							<div className="media-object media-left">
								<i className="fa fa-link"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">Back-end</h3>
								<p>C#</p>
								<p>JavaScript</p>
								<p>AJAX</p>
								<p>PHP</p>
								<p>SQL</p>
								<p>JAVA (Jakarta EE)</p>
							</div>
						</div>
					</div>
					
					<div className="col-md-4 col-xs-11 wow fadeInUp" >
						<div className="media">
							<div className="media-object media-left">
								<i className="fa fa-laptop"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">Front-end</h3>
								<p>HTML</p>
								<p>CSS</p>
								<p>JavaScript</p>
								<p>ReactJS</p>
								<p>WordPress</p>
								<p>jQuery</p>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>	
	</div>		
	)
}