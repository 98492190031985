import React from 'react';

export default function WhatIdo(){
	return(
		<div id="work" className="section">
			<div className="container">
				<div className="row">
					<div className="col-md-12 wow bounce">
						<h2>Skills</h2>
					</div>
                </div>  
                
                <div className="row">
					<div className="col-md-4 col-xs-11 wow fadeInUp" >
						<div className="media">
							<div className="media-object media-left">
								<i className="fa fa-paper-plane"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">Tools</h3>
								<p>Adobe Photoshop</p>
								<p>FileZilla</p>
								<p>Node Package Manager</p>
								<p>Github Desktop/CMD</p>
							</div>
						</div>
					</div>
                    
					<div className="col-md-4 col-xs-11 wow fadeInUp" >
						<div className="media">
							<div className="media-object media-left">
								<i className="fa fa-link"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">Back-end</h3>
								<p>C#</p>
								<p>JavaScript</p>
								<p>AJAX</p>
								<p>PHP</p>
								<p>SQL</p>
							</div>
						</div>
					</div>
                    
					<div className="col-md-4 col-xs-11 wow fadeInUp" >
						<div className="media">
							<div className="media-object media-left">
								<i className="fa fa-laptop"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">Front-end</h3>
								<p>HTML</p>
								<p>CSS</p>
								<p>JavaScript</p>
								<p>ReactJS</p>
								<p>WordPress</p>
								<p>jQuery</p>
							</div>
						</div>
					</div>
                    
				</div>
			</div>
		</div>		
	)
}