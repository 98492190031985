import React, {useState, useRef} from 'react';
import emailjs from '@emailjs/browser';
//import { useNavigate } from "react-router-dom";

export default function Contact(){
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [msg, setMsg] = useState('');
	const [suc, setSuc] = useState('');
	const form = useRef();
	//const navig = useNavigate();

	const handleSubmit = (evt) =>{
		evt.preventDefault();

		emailjs.sendForm('service_i2vtx9z', 'template_itfr8ob', form.current, 'xO8YoFxYj6lCAx_0R')
		    .then((response) => {
		       //console.log(response.text);
		       setSuc(response.text);
			   setTimeout(function(){
				setSuc("Mail Failed")
				//form.current.reset();
			 }, 5000);
		    }, (error) => {
		       //console.log(error.text);
		       setSuc("Mail Failed");
		    });

	}

	return(
		<div id="contact" className="section">
				{suc === "OK" ? 
				<h2 className="prom">Mail Sent! Thank you, <br/><br/>  
				I'll get back to you w/in 1-3 business days.</h2>:<h2 className="prom-err">Looking Forward to hear from you</h2>
				}	
			<div className="container">
				<div className="row">
					<div className="col-lg-5 col-lg-offset-1 col-md-6 wow fadeInUp" >
						<address>
							<p className="contact-title">CONTACT ME</p>
							<p><i className="fa fa-phone"></i> Thinking about providing it.</p>
							<p><i className="fa fa-envelope-o"></i> Please use the form here to contact me.</p>
							<p><i className="fa fa-map-marker"></i> Edmonton, Alberta</p>
						</address>
					</div>
					<div className="col-lg-6 col-md-6 col-xs-10 wow fadeInUp" >
						<form ref={form} onSubmit={handleSubmit} >
							<input 
								name="from_name"
								value={name}
								onChange={(e)=> setName(e.target.value)} 
								type="text" 
								className="form-control" 
								id="name" 
								placeholder="Your Name" 
								required
							/>
							<input 
								name="email" 
								value={email}
								onChange={(e)=> setEmail(e.target.value)} 
								type="email" 
								className="form-control" 
								id="email" 
								placeholder="Your Email" 
								required
							/>
							<textarea 
								name="message" 
								value={msg}
								onChange={(e)=> setMsg(e.target.value)} 
								rows="5" 
								className="form-control" 
								id="message" 
								placeholder="Your Message"
								required>			
							</textarea>
							<input name="send" type="submit" className="form-control" id="send" value="SEND ME" />
						</form>
					</div>
					<div className="col-md-1 col-sm-1"></div>
				</div>
			</div>
		</div>
	)
}