import React from 'react';

export default function Footer(){
	return(
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-md-12 wow fadeIn" >
						<p>Designed by&nbsp;
							<a 
								rel="nofollow noopener" 
								href="https://templatemo.com">
								templatemo
							</a>
						. &nbsp;Modified & Incorporated into ReactJS by Mark.
						</p>
						<p>Credits go to 
							<a rel="nofollow" href="https://pixabay.com"> Pixabay </a> 
							, 
							<a rel="nofollow" href="https://fontawesome.com/docs/web/use-with/react/"> FontAwesome </a>
							, &
							<a rel="nofollow" href="http://unsplash.com"> Unsplash </a> 
							for icons used.
						</p>
						<hr />
						<ul className="social-icon">
							<li><a href="https://www.facebook.com/" className="fa fa-facebook">-acebook</a></li>
							<li><a href="https://twitter.com/?lang=en" className="fa fa-twitter"> Twitter</a></li>
							<li><a href="https://www.instagram.com/" className="fa fa-instagram"> Instagram</a></li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}