import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./Nav";
import Header from './Header';
import WhatIdo from './WhatIdo';
import About from './About';
import Development from './Development';
import Portfolio from './Portfolio';
import Contact from './Contact';
import NoPage from "./NoPage";

const galleryImg = [
  {
    img: 'images/mood-board.jpg'
  },
  {
    img: 'images/wireframe-home.jpg'
  },
  {
    img: 'images/wireframe-home-high.jpg'
  },
  {
    img: 'images/wireframe-green.jpg'
  },
  {
    img: 'images/wireframe-green-high.jpg'
  },
  {
    img: 'images/wireframe-about.jpg'
  },
  {
    img: 'images/wireframe-about-high.jpg'
  },
  {
    img: 'images/wireframe-one.jpg'
  },
  {
    img: 'images/wireframe-two.jpg'
  },
  {
    img: 'images/wireframe-three.jpg'
  },
  {
    img: 'images/wireframe-four.jpg'
  },
]

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Nav />}>
          <Route index element={<Header />} />
          <Route path="whatido" element={<WhatIdo />} />
          <Route path="about" element={<About />} />
          <Route path="development" element={<Development galleryImg={galleryImg}/>} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
