import React from 'react';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
		faCircleLeft,
		faCircleRight,
		faCircleXmark
} from '@fortawesome/free-solid-svg-icons';

export default function Development({galleryImg}){

	const [slideNum, setSlideNum] = useState(0)
	const [openMod, setOpenMod] = useState(false)

	const handleOpenMod = (index) =>{
		setSlideNum(index)
		setOpenMod(true)
	}

	const handleCloseMod = () =>{
		setOpenMod(false)
	}

	const prevImg = () =>{
		slideNum === 0 ? setSlideNum( galleryImg.length - 1 ) : setSlideNum( slideNum - 1 )
	}

	const nextImg = () =>{
		slideNum + 1 === galleryImg.length ? setSlideNum(0) : setSlideNum( slideNum + 1 )
	}

	return(
		<div id="portfolio" className="section development">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className="wow bounce">Development Steps</h2>
						<p>Mood Board <b>,</b> Mid-fidelity wireframes <b>,</b> and High-fidelity wireframes</p>
						<p>( <b>Low-fidelity wireframes</b> are not here because I lost my skecthes for those. )</p>
						<br/>
					</div>

					{openMod && 
						<div className="sliderWrp">
							<FontAwesomeIcon icon={faCircleXmark} size="3x" className="btnClose" onClick={handleCloseMod} />
							<FontAwesomeIcon icon={faCircleLeft} size="3x" className="btnLeft" onClick={prevImg} />
							<FontAwesomeIcon icon={faCircleRight} size="3x" className="btnRight" onClick={nextImg} />

							<div className="col-md-12 fullScreenImgGal">
								<img src={galleryImg[slideNum].img} alt={slideNum + 1} />
							</div>
						</div>
					}

					<div className="galleryCont col-md-12">
						{
							galleryImg && galleryImg.map((slide, index) => {
								return(
									<div className="col-md-4 col-xs-6 wow fadeIn" key={index}>
											<div className="portfolio-thumb">
												<div 
													className="single" 
													
													onClick={()=>handleOpenMod(index)}
												>
													<img src={slide.img} alt={slideNum + 1} width="300" height="300" />
												</div>
											</div>
									</div>
								)
							})
						}
					</div>
					
				</div>
			</div>
		</div>	
	)
}