import React from 'react';
import { Button } from '@mui/material';
import { useState } from 'react';

import Modalport from './Modalport';

export default function Portfolio(){
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const [open5, setOpen5] = useState(false);
	const [open6, setOpen6] = useState(false);
	const [open7, setOpen7] = useState(false);
	const [open8, setOpen8] = useState(false);
	const [open9, setOpen9] = useState(false);
	const [open10, setOpen10] = useState(false);
	const [open11, setOpen11] = useState(false);

	return(
		<div id="portfolio" className="section">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2 className="wow bounce">PORTFOLIO</h2>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen1(true)} id="mybtn">
									<img src="images/portfolio-img12.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Random Quotes</h4>
										<h5>ReactJS + API</h5>
									</div>
								</Button>
								<Modalport open={open1} handleClose={() => setOpen1(false)} title={"Random Quotes App"}
										   img={"images/3-comp.jpg"}
										   tools={{a: 'ReactJS', b: 'API'}}
										   mylink={"https://quote.markalejandro.ca/"}
										   desc={"Coded a Random Quote Generator. It gets a random quote from an API whenever a user clicks a random quote button. Its randomness is the fun of it. You’ll never know if you will get an inspirational, funny or dark quote."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen(true)} id="mybtn">
									<img src="images/portfolio-img14.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>YT clone</h4>
										<h5>ReactJS, MaterialUI, RapidAPI</h5>
									</div>
								</Button>
								<Modalport open={open} handleClose={() => setOpen(false)} title={"Youtube Clone App"}
										   img={"images/2-comp.jpg"}
										   tools={{a: 'ReactJS', b: 'MaterialUI', c: 'RapidAPI'}}
										   mylink={"https://ytbclone.markalejandro.ca/"}
										   desc={"Coded a simple Youtube Clone App with the assistance of a JS Mastery tutorial, where the user can search for videos, see the number of views & likes, see videos categories, see videos channels, and the user can play videos searched too."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen7(true)} id="mybtn">
									<img src="images/portfolio-img9.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Kitchen Web</h4>
										<h5>HTML, CSS, JavaScript</h5>
									</div>
								</Button>
								<Modalport open={open7} handleClose={() => setOpen7(false)} title={"Kitchen Web"}
										   img={"images/5-comp.jpg"}
										   tools={{a: 'HTML', b: 'CSS', c: 'JavaScript'}}
										   mylink={"https://kitchen.markalejandro.ca/"}
										   desc={"Modified this Kitchen Website to achieve certain objectives & personalized it. It has a gallery, blog-cards, contact me and a video player(for now its not about food, but it can be change whenever)."}
								/>
							</div>
						</div>

						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen8(true)} id="mybtn">
									<img src="images/portfolio-img10.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Portfolio Template</h4>
										<h5>HTML, CSS, JavaScript</h5>
									</div>
								</Button>
								<Modalport open={open8} handleClose={() => setOpen8(false)} title={"Portfolio Template"}
										   img={"images/1-comp.jpg"}
										   tools={{a: 'HTML', b: 'CSS', c: 'JavaScript'}}
										   mylink={"https://temp.markalejandro.ca/"}
										   desc={"Modified this Portfolio Template website to achieve certain objectives & personalized it. It has a side navigation, a gallery, a contact me, and about me."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen6(true)} id="mybtn">
									<img src="images/portfolio-img11.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>PHP Simple Login</h4>
										<h5>HTML, PHP, SQL</h5>
									</div>
								</Button>
								<Modalport open={open6} handleClose={() => setOpen6(false)} title={"PHP Simple Login"}
										   img={"images/9-comp.jpg"}
										   tools={{a: 'HTML', b: 'PHP', c: 'SQL'}}
										   mylink={"https://smplelogin.markalejandro.ca/"}
										   desc={"Coded a simple PHP login w/ signup and dashboard. The user can register and have their information saved on the database; then, they can log in to see a simple dashboard displaying their email."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen9(true)} id="mybtn">
									<img src="images/portfolio-img3.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Travler App</h4>
										<h5>HTML, CSS, JavaScript</h5>
									</div>
								</Button>
								<Modalport open={open9} handleClose={() => setOpen9(false)} title={"Travler App"}
										   img={"images/10-comp.jpg"}
										   tools={{a: 'HTML', b: 'CSS', c: 'JavaScript'}}
										   mylink={"https://travlr.markalejandro.ca/"}
										   desc={"Coded a Travler App that has an icon-based navigation. It has an about the company and a card section about people, and a card section abput customer reviews, google-map and pricing."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen5(true)} id="mybtn">
									<img src="images/portfolio-img13.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>PHP Live Search</h4>
										<h5>PHP, SQL, AJAX</h5>
									</div>
								</Button>
								<Modalport open={open5} handleClose={() => setOpen5(false)} title={"PHP Live Search"}
										   img={"images/8-comp.jpg"}
										   tools={{a: 'PHP', b: 'SQL', c: 'AJAX'}}
										   mylink={"https://livesrch.markalejandro.ca/"}
										   desc={"Coded a simple Live Search App to help me quickly look up a game item price I'm keeping tabs on. Prices were last updated around February 9, 2023."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen11(true)} id="mybtn">
									<img src="images/portfolio-img2.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Yanalla Farms</h4>
										<h5>WordPress</h5>
										<h5>Adobe Photoshop</h5>
									</div>
								</Button>
								<Modalport open={open11} handleClose={() => setOpen11(false)} title={"Yanalla Farms"}
										   img={"images/12-comp.jpg"}
										   tools={{a: 'WordPress', b: 'Adobe Photoshop'}}
										   mylink={"https://yanall.markalejandro.ca/"}
										   desc={"A fully customised academic project that involved replicating an existing Joomla-built website's landing page and switching it over to Wordpress. It was not required to code some pages & features of the original website."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<a href="https://markalejandro.ca/" alt="Farm" target="_blank"
								   rel="noreferrer noopener">
									<img src="images/portfolio-img8.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>My updated website</h4>
										<h5>Templatemo + Incorporated into ReactJS</h5>
									</div>
								</a>
							</div>
						</div>
						{/*<div className="col-md-6 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen4(true)} id="mybtn">
									<img src="images/portfolio-img5.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Movie Search by Description</h4>
										<h5>MongoDB Atlas</h5>
									</div>
								</Button>
								<Modalport open={open4} handleClose={() => setOpen4(false)}
										   title={"Movie Search by Description"} img={"images/7-comp.jpg"}
										   tools={{a: 'MongoDB Atlas', b: 'HTML'}}
										   mylink={"https://movi.markalejandro.ca/index.php"}
										   desc={"Coded a simple Movie Search App. It gets all the movie details data from MongoDB Atlas based on the user search matching the movie description."}
								/>
							</div>
						</div>*/}
						<div className="col-md-6 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen3(true)} id="mybtn">
									<img src="images/portfolio-img4.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Weather App</h4>
										<h5>ReactJS + API</h5>
									</div>
								</Button>
								<Modalport open={open3} handleClose={() => setOpen3(false)} title={"Weather App"}
										   img={"images/6-comp.jpg"}
										   tools={{a: 'ReactJS', b: 'API'}}
										   mylink={"https://weather.markalejandro.ca/"}
										   desc={"Coded a simple Weather App. It gets the current weather data from OpenWeatherMap API every time a user searches for a valid city."}
								/>
							</div>
						</div>
						<div className="col-md-6 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen10(true)} id="mybtn">
									<img src="images/portfolio-img6.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Photo Stocks</h4>
										<h5>HTML, CSS, JavaScript</h5>
									</div>
								</Button>
								<Modalport open={open10} handleClose={() => setOpen10(false)} title={"Photo Stocks"}
										   img={"images/11-comp.jpg"}
										   tools={{a: 'HTML', b: 'CSS', c: 'JavaScript'}}
										   mylink={"https://photos.markalejandro.ca/"}
										   desc={"Another fully customised academic project provided to us when I was a student to helps us practice coding for different devices screen sizes."}
								/>
							</div>
						</div>
						<div className="col-md-4 col-xs-6 wow fadeIn">
							<div className="portfolio-thumb">
								<Button onClick={() => setOpen2(true)} id="mybtn">
									<img src="images/portfolio-img1.jpg" className="img-responsive"
										 alt="portfolio img"/>
									<div className="portfolio-overlay">
										<h4>Jeopardy Rando Edition</h4>
										<h5>ReactJS + API</h5>
									</div>
								</Button>
								<Modalport open={open2} handleClose={() => setOpen2(false)}
										   title={"Jeopardy Rando Edition"} img={"images/4-comp.jpg"}
										   tools={{a: 'ReactJS', b: 'API'}}
										   mylink={"https://jeopardy.markalejandro.ca/"}
										   desc={"Coded a Jeopardy App questionnaire game. It gets the jeopardy question points amount, Category, and Question & Answer data from jService API randomly every time the user clicks next. It’s fun to play individually or with friends."}
								/>
							</div>
						</div>

					</div>
				</div>
		</div>

	)
}